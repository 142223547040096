<template>
    <div class="relative">
        <div class="flex rounded-md">
            <span v-if="addOn" class="inline-flex shadow-xs items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">{{ addOn }}</span>

            <input
                :maxlength="maxlength"
                :type="type"
                :disabled="isDisabled"
                v-bind="$attrs"
                v-mask="mask"
                @keypress.enter="$emit('keypress-enter')"
                @keydown.enter="$emit('keydown-enter')"
                @keydown.tab="$emit('keydown-tab')"
                @input="handleInput"
                @change="handleChange"
                @blur="handleBlur"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-xs placeholder-gray-400 focus:outline-hidden focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                :class="{
                    'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': error,
                    'rounded-none rounded-r-md flex-1': addOn,
                    'rounded-none rounded-l-md flex-1': addOnLeft,
                    'rounded-md': !addOn || !addOnLeft,
                    'text-right': rightAlign
                }"
            >

            <span v-if="addOnLeft" class="inline-flex shadow-xs items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">{{ addOnLeft }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseInput',
    inheritAttrs: false,
    props: {
        type: {default:'text', type:String},
        maxlength: {default: '', type:String},
        disabled: {default:false, type:Boolean},
        loading: {default:false, type:Boolean},
        error: {default:false, type:Boolean},
        mask: {type:String},
        addOn: {type:String},
        addOnLeft: {type:String},
        rightAlign: { default: false, type: Boolean },
    },
    methods:{
        handleInput({target}){
            this.$emit('input', target.value);
        },
        handleChange({target}){
            this.$emit('change', target.value);
        },
        handleBlur({target}){
            this.$emit('blur', target.value);
        }
    },
    computed:{
        isDisabled(){
            return this.loading || this.disabled;
        },
    }
};
</script>
